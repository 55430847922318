<template>
  <vx-card title="Inbound">
    <vs-tabs>
      <vs-tab label="External Order">
        <ExternalOrder></ExternalOrder>
      </vs-tab>
      <vs-tab label="Internal Order">
        <div class="tab-text">
          <InternalOrder></InternalOrder>
        </div>
      </vs-tab>
      <vs-tab label="Inter Warehouse">
        <div class="tab-text">
          <InterWarehouse></InterWarehouse>
        </div>
      </vs-tab>
      <vs-tab label="Customer Return">
        <div class="tab-text">
          <SalesReturn></SalesReturn>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import ExternalOrder from "./external-order";
import InternalOrder from "./internal-order";
import InterWarehouse from "./inter-warehouse";
import SalesReturn from "./sales-return";
export default {
  components: {
    ExternalOrder,
    InternalOrder,
    InterWarehouse,
    SalesReturn,
  },
};
</script>