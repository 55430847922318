<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Item Line</h4>
    </span>

    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="
              p-3
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Quantity</vs-th>
        <!-- <vs-th>Batch</vs-th> -->
        <!-- <vs-th>Status Item</vs-th> -->
        <!-- <vs-th>Value</vs-th> -->
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].item_name">
            <!-- <div class="vx-row ml-0"> -->
              <span class="w-5/5">{{ data[indextr].item_name }}</span><br>
              <span class="w-5/5">Batch: <b>{{ data[indextr].batch }}</b></span>
            <!-- </div> -->
          </vs-td>
          <vs-td>
            <span class="w-5/5"><b>Order</b> : {{ data[indextr].quantity }} {{ data[indextr].item_unit }}</span><br>
            <span class="w-5/5"><b>Received</b> : {{ data[indextr].quantity_received }} {{ data[indextr].item_unit }}</span><br>
            <span class="w-5/5">Convert to : </span><br>
            <li v-for="dt in data[indextr].convert_to" :key="dt.unit" class="ml-6">
              <ul>
                <span class="w-5/5">{{ dt.quantity }} {{ dt.unit }}</span><br>
                <div v-for="dt2 in dt.status" :key="dt2.is_damaged">
                  <span v-if="dt2.is_damaged == 0"><b>Pass</b> : {{ dt2.quantity }} {{ dt.unit }} <i>({{ (dt.item_value*dt2.quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }})</i></span>
                  <span v-else><b>Damaged</b> : {{ dt2.quantity }} {{ dt.unit }} <i>({{ (dt.item_value*dt2.quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }})</i></span>
                </div>
              </ul>
            </li>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    poId: {
      type: Number,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        table: {
          data: [],
          length: 5,
          page: 1,
          search: "",
          order: "id",
          sort: "desc",
          total: 0,
          totalPage: 0,
          totalSearch: 0,
          limits: [5, 10, 25, 50, 100, "All"],
          start: 1,
          end: 0,
        },
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/report/inbound/line", {
          params: {
            id: this.poId,
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            console.log(resp.data.records)
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.poId) {
      this.getData();
    }
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {
    poId() {
      Object.assign(this.$data, this.initialState());
      this.getData();
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>